import OpacityParallax from "@components/parallax-opacity"
import ParallaxSticky from "@components/parallax-sticky"
import Scrollshow from "@components/scrollshow"
import {
  Block,
  CenterCite,
  CenterText,
  Figure,
  Intro,
  IntroImage,
  NavLink,
  NavLinks,
  ParallaxBlock,
  StoryHeading,
  StoryHeadingBg,
  StoryMeta,
  StoryTitle,
  TextBlock,
  VideoQuote,
} from "@components/story-components"
import StoryPicker from "@components/storypicker"
import Video from "@components/video"
import YoutubeVideo from "@components/YoutubeVideo"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import React from "react"
import { FluidImg } from "src/types"

import ahmadMp4 from "../../assets/ahmad.mp4"
import ahmadWebM from "../../assets/ahmad.webm"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import LeftIcon from "../../images/chevron-left.inline.svg"
import RightIcon from "../../images/chevron-right.inline.svg"

type Props = {
  data: {
    ahmad: FluidImg
    ahmadOffice: FluidImg
    livingRoom: FluidImg
    manOnSwing: FluidImg
    everyDayLife: FluidImg
    everyDayLife2: FluidImg
  }
}

const IndexPage = ({ data }: Props) => {
  return (
    <Layout>
      <SEO title="Ahmad's story" />
      <Block>
        <Video
          sources={[
            { src: ahmadWebM, type: "video/webm" },
            { src: ahmadMp4, type: "video/mp4" },
          ]}
        />
        <VideoQuote>
          <CenterCite maxWidth="63rem" color="wheat">
            Ahmad's Story
            <StoryMeta>Malaysia</StoryMeta>
          </CenterCite>
        </VideoQuote>
        <StoryHeading>
          <StoryHeadingBg>
            <NavLinks>
              <NavLink to="/story/rahma">
                <LeftIcon height="1em" strokeWidth="2px" /> Previous
              </NavLink>
              <NavLink to="/story/khairul">
                Next story <RightIcon height="1em" strokeWidth="2px" />
              </NavLink>
            </NavLinks>
            <StoryTitle maxWidth="100ch">
              ‘When the war broke out in Syria I felt angry inside and I wanted
              to do something about it’
            </StoryTitle>
          </StoryHeadingBg>
        </StoryHeading>
      </Block>
      <Intro>
        <IntroImage fluid={data.ahmad.childImageSharp.fluid} />
        <CenterText fontSize={["xl", "2xl"]}>
          I watched the news and followed social media to keep up with the daily
          developments. From the things I watched and read online, I believed my
          Muslim brothers were being oppressed.
        </CenterText>
      </Intro>
      <OpacityParallax
        bg="cyprus"
        threshold={0.5}
        backgroundProps={{
          fluid: data.ahmadOffice.childImageSharp.fluid,
        }}
      >
        <ParallaxSticky mt="120vh">
          <TextBlock color="wheat">
            <CenterText fontSize={["xl", "2xl"]}>
              I got in touch with jihadists through social media. Most of them
              were asking for us to help spread their ideology and raise funds
              to assist some Malaysians who wished to go over and help other
              jihadists in Syria. I created my own Telegram group, and then
              recruited my members through social media by chatting to them on
              messenger.
            </CenterText>
          </TextBlock>
        </ParallaxSticky>
      </OpacityParallax>
      <TextBlock bg="wheat" color="cyprus">
        <CenterText fontSize={["xl", "2xl"]}>
          I didn’t have any family problems at the time or anything, I actually
          have a very good relationship with my family, my parents, my siblings
          and I always spend time with my wife, kids. I was stable financially
          and had a career. I was respected in my workplace.
        </CenterText>
      </TextBlock>
      <TextBlock color="cyprus">
        <Figure>
          <Image fluid={data.livingRoom.childImageSharp.fluid} />
        </Figure>

        <CenterText fontSize={["xl", "2xl"]}>
          Most of my friends were shocked when I was arrested, but I felt
          strangely relieved. The fight is a burden, especially I came to feel
          that the fight was wrong. Malaysia is a very peaceful Muslim country.
          Nobody is stopping you from praying, from practicing anything about
          your religion, as long as you don’t disturb other people’s rights. I
          didn’t want to promote violence.
        </CenterText>
      </TextBlock>
      <Scrollshow
        fluidImages={[
          data.everyDayLife.childImageSharp.fluid,
          data.everyDayLife2.childImageSharp.fluid,
        ]}
      />
      <TextBlock bg="midnight" color="wheat">
        <CenterText fontSize={["xl", "2xl"]}>
          When I was done with the rehabilitation program and came out from
          prison, I said to myself, ‘I’m a new person, and I am thankful I have
          gained so much knowledge through the programme and perspective’. When
          I think back, I am scared about what would have happened if I had gone
          too far down the rabbit hole and it would have been very difficult to
          come back. Or maybe there would have been no way to have come back
          from it again.
        </CenterText>
      </TextBlock>
      <ParallaxBlock
        scaleFactor={1.6}
        backgroundProps={{
          fluid: data.manOnSwing.childImageSharp.fluid,
          loading: "eager",
        }}
      />

      <TextBlock p="" py="5xl" bg="midnight">
        <Figure>
          <YoutubeVideo videoId="dyzFEjaRYq8" />
        </Figure>
      </TextBlock>
      <StoryPicker />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    ahmad: file(relativePath: { eq: "ahmad.jpg" }) {
      ...BlockImage
    }
    ahmadOffice: file(relativePath: { eq: "ahmad/ahmad_office.jpg" }) {
      ...BlockImage
    }
    livingRoom: file(relativePath: { eq: "ahmad/living_room.jpg" }) {
      ...BlockImage
    }
    manOnSwing: file(relativePath: { eq: "ahmad/man_on_swing.jpg" }) {
      ...BlockImage
    }
    everyDayLife: file(relativePath: { eq: "ahmad/everyday_life.jpg" }) {
      ...BlockImage
    }
    everyDayLife2: file(relativePath: { eq: "ahmad/everyday_life2.jpg" }) {
      ...BlockImage
    }
  }
`

export default IndexPage
